// In your AboutCompanyPage.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactUs from '../components/ContactUsComponent';

const ContactUsPage = () => {
  return (
    <>
      <Header />
      <ContactUs />
      <Footer />
    </>
  );
};

export default ContactUsPage;