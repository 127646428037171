import React from 'react';
import Slider from 'react-slick';
import styled, { keyframes } from 'styled-components';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import slider1 from '../assets/slider1.png';



const fadeAnimation = keyframes`
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
`;

const StyledSlider = styled(Slider)`
  position: relative;
  margin: 0 auto;
  overflow: hidden;

    img {
      width: 100%;
      max-height: 60vh; /* Set height to 60% of the viewport height */
      object-fit: cover;
      display: block;
      transition: transform 0.5s ease;
    }
  }

  }


 

 
`;

// Text fade-in animation
const fadeInText = keyframes`
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const TextOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column; /* Arrange children in a column */
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  padding: 15px;
  animation: ${fadeInText} 1.5s ease-out;
  z-index: 10;

 

 

  @media (max-width: 768px) {
   
    p {
      font-size: 1rem;
    }
  }
`;

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 2500,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 3000,
          arrows: false,
          adaptiveHeight: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          autoplaySpeed: 3500,
        }
      }
    ]
  };

  const slidesData = [
    {
      img: slider1,
     
    },
  
  ];

  return (
    <StyledSlider {...settings}>
      {slidesData.map((slide, index) => (
        <div key={index}>
          <img src={slide.img} alt={`Slide ${index}`} />
          
          <TextOverlay>
            <h2>{slide.title}</h2>
            <p>{slide.description}</p>
          </TextOverlay>
        </div>
      ))}
    </StyledSlider>
  );
};

export default Carousel;
