import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Feather, RefreshCw, Globe } from 'react-feather';


import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import bimage from "../assets/slider1.jpg";

import profile from "../assets/profile.jpg";

// Keyframes for animations
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(50px); }
  to { opacity: 1; transform: translateY(0); }
`;

const slideIn = keyframes`
  from { opacity: 0; transform: translateX(-100%); }
  to { opacity: 1; transform: translateX(0); }
`;

// Styled components
const HeroSection = styled.div`
  height: 55vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.5);

  video {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  h1, p {
    z-index: 1;
    font-size: 4rem;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
  }

  p {
    margin-top: 10px;
    font-size: 1.5rem;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 3rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`;

const AboutContainer = styled.section`
  padding: 40px;
  animation: ${fadeIn} 1.5s ease-out;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 30px;
  background: ${({ inverse }) => inverse ? '#f4f4f4' : 'white'};
  flex-direction: ${({ inverse }) => inverse ? 'row-reverse' : 'row'};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: box-shadow 0.3s ease;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 40px 20px;
  }
`;

const TextBlock = styled.div`
  flex: 1;
  padding: 20px;

  h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.3rem;
    color: #333;
    line-height: 1.8;
  }

  @media (max-width: 768px) {
    padding: 10px;
    p {
      font-size: 1.1rem;
    }
  }
`;

const ImageBlock = styled.div`
  flex: 1;
  padding: 20px;
  text-align: center;
  animation: ${slideIn} 1s ease-out;
  
  img {
    max-width: 80%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    img {
      max-width: 100%;
    }
  }
`;

const Timeline = styled.div`
  background: #f8f8f8;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const TimelineItem = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 10px;
  border-left: 4px solid #102a43;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Year = styled.span`
  font-size: 1.8rem;
  font-weight: bold;
  margin-right: 10px;
  color: #102a43;
`;

const Testimonials = styled.div`
  padding: 50px;
  background: #e6e6e6;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  margin-bottom: 20px;

  p {
    font-size: 1.2rem;
    color: #333;
    line-height: 1.6;
    text-align: center;
  }

  h3 {
    margin-top: 20px;
    font-size: 1.8rem;
    color: #102a43;
    text-align: center;
  }
`;

const SustainabilitySection = styled(Section)`
  background: #defade;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  h2 {
    color: #2a652d;
    margin-bottom: 20px;
  }

  p {
    color: #285928;
    font-size: 1.2rem;
  }

  div {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  svg {
    font-size: 2.5rem;
    color: #2a652d;
    margin-right: 10px;
  }
`;

const TeamGridContainer = styled.section`
  padding: 30px 30px;
  text-align: center;
  background: linear-gradient(to bottom, #ffffff, #f0f9ff);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
`;

const TeamGridHeader = styled.h2`
  font-size: 3rem;
  color: #102a43;
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: ${fadeIn} 0.8s ease-out;
  
`;

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  animation: ${fadeIn} 1s ease-out;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const TeamMember = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  }

  img {
    width: 100%;
    max-width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #102a43;
    transition: border 0.3s ease;
  }

  h3, p {
    color: #102a43;
    margin: 10px 0;
  }

  h3 {
    font-size: 1.8rem;
    font-weight: 600;
  }

  p {
    font-size: 1.3rem;
    opacity: 0.7;
  }
`;

const IconTree = styled(Feather)`
  font-size: 2rem;
  color: green;
`;

const settings = {
  dots: true,
  infinite: true,
  speed: 600,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000,
};

// Component
const AboutCompany = () => {
  return (
    <>
     
      <AboutContainer>
        <Section>
          <TextBlock>
            <h2>What we are?</h2>
            <p>
            Keys Limited is a cutting-edge software company that empowers businesses and individuals to unlock their full potential. Our innovative solutions and expert services are designed to unlock new possibilities, drive growth, and success. With a passion for excellence and a commitment to delivering exceptional results, Keys Limited is the trusted partner for all your software needs
            </p>
          </TextBlock>
          <ImageBlock>
            <img src={bimage} alt="Networking solutions" />
          </ImageBlock>
        </Section>
        <Timeline>
          
          <TimelineItem>
            <Year>2021</Year>
            <p>Founded with a vision to provide IT services globally.</p>
          </TimelineItem>
        
        </Timeline>
       
        <TeamGridContainer>
          <TeamGridHeader>Our Team</TeamGridHeader>
          <TeamGrid>
            <TeamMember>
             
              <h3>M Kashif Raza</h3>
              <p>CEO</p>
            </TeamMember>
            <TeamMember>
            
            <h3>M-ASIF</h3>
            <p>Senior Full Stack Developer</p>
            </TeamMember>
            <TeamMember>
            <h3>Hurrara Z</h3>
            <p>Full Stack Developer</p>
            </TeamMember>
          
           
          </TeamGrid>
        </TeamGridContainer>
      </AboutContainer>
    </>
  );
};

export default AboutCompany;
