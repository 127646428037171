// In your AboutCompanyPage.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ServicesComponent from '../components/servicescomponent';

const ServicesPage = () => {
  return (
    <>
      <Header />
      <ServicesComponent />
      <Footer />
    </>
  );
};

export default ServicesPage;